<template>
  <v-main class="ml-0 mt-2 mr-2">
    <div class="ml-2">
   
      <v-card max-width="100%" elevation="1">
      <v-alert
        style="max-height: 47px;!important"
        color="topo_menu"
        class="text-left"
      >
        <v-breadcrumbs :items="itemsBread">
          <template v-slot:divider>
            <v-icon>mdi-forward</v-icon>
          </template>
        </v-breadcrumbs>
      </v-alert>     

           <v-row class="mx-auto mt-0 ml-5 pa-1">
            
            <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                <v-radio-group
                   v-model="dados.tipo_data"
                   @change="buscaResultado()"
                  row
                  hide-details=""
                >
                  <v-radio
                    label="Pagamento"
                    value="pagamento"
                    
                  ></v-radio>
                  <v-radio
                    label="Lancamento"
                    value="lancamento"
                    
                  ></v-radio>
                </v-radio-group>
                
            </v-col>
          
            </v-row>
          <v-row class="mx-auto mt-0 ml-0 pa-5">
            <v-col v-show="false" cols="4" xl="2" lg="2" md="3" sm="4" xs="6">
               <v-select 
                transition="fab-transition"
                :items="listaMesesAno"
                v-model="selmesAtual"
                name="mes"
                label="Selecione o Mês"
                item-value="mes"
                item-text="mesAno"
                filled               
             
                clearable
                hide-details
              ></v-select>
            </v-col>
           <v-col cols="12" xl="2" lg="2" md="3" sm="4" xs="6">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                transition="scroll-y-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateInicialFormatted"
                    label="data inicial"
                    persistent-hint
                    prepend-inner-icon="event"
                    filled
                    
                    v-on="on"
                    @input="
                      dados.data_final = parseDate(dateFinalFormatted);                      
                    "
                    
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="pt-br"
                  v-model="dados.data_inicial"
                  no-title
                  @input="
                    menu2 = false;buscaResultado()
                  
                  "
                  hide-details
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="3" sm="3" xs="3">
              <v-menu
                v-model="menuFin"
                :close-on-content-click="false"
                transition="scroll-y-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateFinalFormatted"
                    label="data final"
                    persistent-hint
                    prepend-inner-icon="event"
                    filled
                    
                    v-on="on"
                    @input="
                      dados.data_final = parseDate(dateFinalFormatted);
                      ;
                    "
                   
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="pt-br"
                  v-model="dados.data_final"
                  no-title
                  @input="menuFin = false;buscaResultado()"
                  hide-details
                ></v-date-picker>
              </v-menu>
            </v-col>

           
            
             <v-col cols="6" xl="1" lg="1" md="2" sm="6" xs="2">
                  <v-btn
                      
                      color="error"
                      @click="buscaResultadoPdf()"
                      
                      small
                      dark
                    >
                    <v-icon>mdi-pdf-box</v-icon>
                    </v-btn>                    
            </v-col>
            <v-col cols="6" xl="1" lg="1" md="2" sm="6" xs="2">
              <v-btn
                      
                      color="success"
                      @click="buscaResultadoExcel()"
                      
                      dark
                      small
                    >
                    <v-icon>mdi-microsoft-excel</v-icon>
                    </v-btn>
            </v-col>
          </v-row>
          <v-row class="mx-auto mt-0 ml-5 pa-1">
              <v-col cols="12" xl="4" lg="4" md="6" sm="8" xs="12">
              
                  <v-autocomplete
                transition="fab-transition"
                :items="listaMeses"
                v-model="selmes"
                name="mes"
                label="Comparar com "
                item-value="mes"
                item-text="mesAno"
                filled               
                small-chips
                multiple
                clearable
                hide-details
              ></v-autocomplete>
               </v-col>
               <v-col  cols="12" xl="1" lg="1" md="2" sm="6" xs="2">
                  <v-btn
                      
                      color="success"
                      @click="buscaResultado()"
                      block
                      small
                      dark
                    >
                    Gerar
                    </v-btn>                    
            </v-col>
          </v-row>          
          
      
    </v-card>
      <v-card class="mt-5">
      
     <v-row>
      <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
           
     
      <!--<v-data-table
        :headers="headers"
        :items="listaVendas"
        :loading="loading_table"
         loading-text="Carregando... Aguarde"
        :search="search"
        :hide-default-footer="true"
         disable-pagination               
        dense
        hide-actions
        no-data-text="Nenhum resultado encontrado!"
        no-results-text="Nenhum resultado encontrado!"
        class="elevation-1"
      >
     
       <template v-slot:item.codigo="{ item }">
              <span class="caption">{{ item.codigo }}</span>
        </template>
        <template v-slot:item.grupo="{ item }">
              <span class="caption">{{ item.grupo }}</span>
        </template>
        <template v-slot:item.vlr_valor="{ item }">
              <span class="caption">{{ formatar_valor(item.vlr_valor) }}</span>
        </template>
        <template v-slot:item.perc_vendas="{ item }">
              <span class="caption">{{ item.perc_vendas }}</span>
        </template>
        <template v-slot:item.perc_dif="{ item }">
          <span class="caption">{{ formatar_valor(item.perc_dif) }}</span>
        </template>
        <template slot="body.append">
          <tr class="black--text">
            <th class="caption">Total</th>
            <th></th>
            <th class="text-right">{{ formatar_valor(sumField('vlr_valor',this.listaVendas).toFixed(2)) }}</th>
            <th class="text-right">{{ formatar_valor( ((sumField('vlr_valor',this.listaVendas)*100) /this.totalVendas).toFixed(2)) }}</th>
            <th></th>                        
                        
          </tr>
        </template>
            
      
       
      </v-data-table>  

       <v-card  v-if="listaCompras">        
        <p class="ml-2 caption text-left ">Status - Compras</p>                    
     
      <v-data-table
        :headers="headers"
        :items="listaCompras"
        :loading="loading_table"
        :search="search"
         loading-text="Carregando... Aguarde"
         :hide-default-footer="true"
        dense
        no-data-text="Nenhum resultado encontrado!"
        no-results-text="Nenhum resultado encontrado!"
        class="elevation-1"
      >
     
        <template v-slot:item.codigo="{ item }">
              <span class="caption">{{ item.codigo }}</span>
        </template>
        <template v-slot:item.grupo="{ item }">
              <span class="caption">{{ item.grupo }}</span>
        </template>
        <template v-slot:item.perc_vendas="{ item }">
              <span class="caption">{{ item.perc_vendas }}</span>
        </template>
        <template v-slot:item.vlr_valor="{ item }">
              <span class="caption">{{ formatar_valor(item.vlr_valor) }}</span>
        </template>
        <template v-slot:item.perc_dif="{ item }">
          <span class="caption">{{ formatar_valor(item.perc_dif) }}</span>
        </template>
        <template slot="body.append">
          <tr class="black--text">
            <th class="caption">Total</th>
            <th></th>
            <th class="text-right">{{ formatar_valor(sumField('vlr_valor',this.listaCompras).toFixed(2)) }}</th>
            <th class="text-right">{{ formatar_valor( ((sumField('vlr_valor',this.listaCompras)*100) /this.totalVendas).toFixed(2)) }}</th>
            <th></th>                    
          </tr>
        </template>
       
      </v-data-table>  
      </v-card>
      <v-card v-if="listaDespesas">        
        <p class="ml-2 caption text-left ">Status - Despesas</p>                    
     
      <v-data-table
        :headers="headers"
        :items="listaDespesas"
       :loading="loading_table"
         loading-text="Carregando... Aguarde"
        :search="search"
         :hide-default-footer="true"
        dense
        no-data-text="Nenhum resultado encontrado!"
        no-results-text="Nenhum resultado encontrado!"
        class="elevation-1"
      >
     
        <template v-slot:item.codigo="{ item }">
              <span class="caption">{{ item.codigo }}</span>
        </template>
        <template v-slot:item.grupo="{ item }">
              <span class="caption">{{ item.grupo }}</span>
        </template>
        <template v-slot:item.perc_vendas="{ item }">
              <span class="caption">{{ item.perc_vendas }}</span>
        </template>
        <template v-slot:item.vlr_valor="{ item }">
              <span class="caption">{{ formatar_valor(item.vlr_valor) }}</span>
        </template>
        <template v-slot:item.perc_dif="{ item }">
          <span class="caption">{{ formatar_valor(item.perc_dif) }}</span>
        </template>
        <template slot="body.append">
          <tr class="black--text">
            <th class="caption">Total</th>
            <th></th>
            <th class="text-right">{{ formatar_valor(sumField('vlr_valor',this.listaDespesas).toFixed(2)) }}</th>
            <th class="text-right">{{ formatar_valor( ((sumField('vlr_valor',this.listaDespesas)*100) /this.totalVendas).toFixed(2)) }}</th>
            <th></th>                    
          </tr>
        </template>        
       
      </v-data-table>  
       </v-card>
    
    <v-card v-if="listaInvestimentos">        
        <p   class="ml-2 caption text-left ">Status - Investimentos</p> 
            
      <v-data-table 
        :headers="headers"
        :items="listaInvestimentos"
        :loading="loading_table"
         loading-text="Carregando... Aguarde"
        :search="search"
         :hide-default-footer="true"
        dense
        no-data-text="Nenhum resultado encontrado!"
        no-results-text="Nenhum resultado encontrado!"
        class="elevation-1"
      >
     
        <template v-slot:item.codigo="{ item }">
              <span class="caption">{{ item.codigo }}</span>
        </template>
        <template v-slot:item.grupo="{ item }">
              <span class="caption">{{ item.grupo }}</span>
        </template>
        <template v-slot:item.perc_vendas="{ item }">
              <span class="caption">{{ item.perc_vendas }}</span>
        </template>
        <template v-slot:item.vlr_valor="{ item }">
              <span class="caption">{{ formatar_valor(item.vlr_valor) }}</span>
        </template>
        <template v-slot:item.perc_dif="{ item }">
          <span class="caption">{{ formatar_valor(item.perc_dif) }}</span>
        </template>
        <template slot="body.append">
          <tr class="black--text">
            <th class="caption">Total</th>
            <th></th>
            <th class="text-right">{{ formatar_valor(sumField('vlr_valor',this.listaInvestimentos).toFixed(2)) }}</th>
            <th class="text-right">{{ formatar_valor( ((sumField('vlr_valor',this.listaInvestimentos)*100) /this.totalVendas).toFixed(2)) }}</th>
            <th></th>                    
          </tr>
        </template>
       
      </v-data-table>  
  </v-card>


 <v-card v-if="listaOutrosCreditos">        
        <p   class="ml-2 caption text-left ">Status - Outros Créditos</p> 
            
      <v-data-table 
        :headers="headers"
        :items="listaOutrosCreditos"
         :loading="loading_table"
         loading-text="Carregando... Aguarde"
        :search="search"
         :hide-default-footer="true"
        dense
        no-data-text="Nenhum resultado encontrado!"
        no-results-text="Nenhum resultado encontrado!"
        class="elevation-1"
      >
     
        <template v-slot:item.codigo="{ item }">
              <span class="caption">{{ item.codigo }}</span>
        </template>
        <template v-slot:item.grupo="{ item }">
              <span class="caption">{{ item.grupo }}</span>
        </template>
        <template v-slot:item.perc_vendas="{ item }">
              <span class="caption">{{ item.perc_vendas }}</span>
        </template>
        <template v-slot:item.vlr_valor="{ item }">
              <span class="caption">{{ formatar_valor(item.vlr_valor) }}</span>
        </template>
        <template v-slot:item.perc_dif="{ item }">
          <span class="caption">{{ formatar_valor(item.perc_dif) }}</span>
        </template>
        <template slot="body.append">
          <tr class="black--text">
            <th class="caption">Total</th>
            <th></th>
            <th class="text-right">{{ formatar_valor(sumField('vlr_valor',this.listaOutrosCreditos).toFixed(2)) }}</th>
            <th class="text-right">{{ formatar_valor( ((sumField('vlr_valor',this.listaOutrosCreditos)*100) /this.totalVendas).toFixed(2)) }}</th>
            <th></th>                    
          </tr>
        </template>
       
      </v-data-table>  
  </v-card>



   <v-card v-if="listaOutrosDebitos">        
        <p   class="ml-2 caption text-left ">Status - Outros Débitos</p> 
            
      <v-data-table 
        :headers="headers"
        :items="listaOutrosDebitos"
      :loading="loading_table"
         loading-text="Carregando... Aguarde"
        :search="search"
         :hide-default-footer="true"
        dense
        no-data-text="Nenhum resultado encontrado!"
        no-results-text="Nenhum resultado encontrado!"
        class="elevation-1"
      >
     
        <template v-slot:item.codigo="{ item }">
              <span class="caption">{{ item.codigo }}</span>
        </template>
        <template v-slot:item.grupo="{ item }">
              <span class="caption">{{ item.grupo }}</span>
        </template>
        <template v-slot:item.perc_vendas="{ item }">
              <span class="caption">{{ item.perc_vendas }}</span>
        </template>
        <template v-slot:item.vlr_valor="{ item }">
              <span class="caption">{{ formatar_valor(item.vlr_valor) }}</span>
        </template>
        <template v-slot:item.perc_dif="{ item }">
          <span class="caption">{{ formatar_valor(item.perc_dif) }}</span>
        </template>
        <template slot="body.append">
          <tr class="black--text">
            <th class="caption">Total</th>
            <th></th>
            <th class="text-right">{{ formatar_valor(sumField('vlr_valor',this.listaOutrosDebitos).toFixed(2)) }}</th>
            <th class="text-right">{{ formatar_valor( ((sumField('vlr_valor',this.listaOutrosDebitos)*100) /this.totalVendas).toFixed(2)) }}</th>
            <th></th>                    
          </tr>
        </template>
       
      </v-data-table>
      
  </v-card>-->
  <v-card elevation="1" class="ma-2" >
    <v-progress-linear :active="loading_table" indeterminate ></v-progress-linear>
    <div  style="padding:20px" v-html="tabela"></div>
</v-card>
     </v-col>
    </v-row>
    </v-card>
    </div>
    
       <a href="#" target="_blank" id="linkDownload" download></a>
  </v-main>
  
  </v-container>
</template>

<script>
var moment = require("moment");

import { formata } from "../plugins/format";
//import DialogItensLancamento from "./DialogItensLancamento.vue";
const auth = {
  headers: {
    Authorization: "bearer " + window.sessionStorage.getItem("token"),
  },
};
export default {
  name: "Extrato",
  mixins: [formata],
    props: {
    source: String,
  },
  mounted(){
    this.buscaResultado();
    this.buscaMesesAno();
    
    
    //console.log(this.listaInvestimentos)
  },
  data: (vm) => ({
    selmes: null,
    selmesAtual:null,
    selFormaPagto: "",
   
    dialogItens: false,
    id_pai: "",
    dados: {
      descricao: "",     
      tipo_data: "pagamento",      
      data_inicial: moment().format("YYYY-MM-01"),
      data_final: moment().format("YYYY-MM-DD"),

    
    },
      itemsBread: [
      {
        text: "Financeiro",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Resultado Operacional",
        disabled: false,
        href: "/dashboard/resultado_operacional",
      },
    ],
    selDel: "",
    
    barSaldos: true,
    barTabela: false,
    isLoading: false,
    // date_ini: moment().format("YYYY-MM-01"),
    //date_vencimento: new Date().toISOString().substr(0, 10),
    //date_pagamento: new Date().toISOString().substr(0, 10),
    datePagamentoFormatted: moment().format("DD/MM/YYYY"),
    //data_inicial: moment().format("YYYY-MM-01"),
    //data_final: new Date().toISOString().substr(0, 10),
    dateInicialFormatted: moment().format("01/MM/YYYY"),
    dateFinalFormatted: moment().format("DD/MM/YYYY"),
    menu1: false,
    menu2: false,
    menu3: false,
    menuFin: false,
    loading_table: false,
     search: "",
    loading_filtrar: false,
    loading_baixar: false,
    //grupos: [],

    historicos: [],
    fornecedores: [],
    listaTipoDoc: [],
    listaVendas:[],
    listaCompras:[],
    listaDespesas:[],
    listaInvestimentos:[],
    listaOutrosCreditos:[],
    listaOutrosDebitos:[],
    saldoAnterior:0,
    tabela:"",
    headers: [
       {
        text: "Codigo",
        value: "codigo",
        align: "left",
        width: '5%', 
        sortable:false
      },
      {
        text: "Financeiro",
        value: "grupo",
        width: '20%', 
        sortable:false 
      },
       {
        text: "Valor",
        value: "vlr_valor",
        align: "right",
        width: '10%' , 
        sortable:false
      },
                   
      {
        text: "% S/venda",
        value: "perc_vendas",
        align: "right",
        width: '10%', 
        sortable:false 
        
      },
       {
        text: "Objetivo",
        value: "vlr_objetivo",
        align: "right",
        width: '10%' , 
        sortable:false
      },
      {
        text: "Dif",
        value: "perc_dif",
        align: "right",
        width: '10%' , 
        sortable:false
      },           

    ],
    listaContas: [],
    v_autoselect: null,
   listaMeses:[],
   listaMesesAno:[]
  }),
  computed: {
    dateInFormatted() {
      return this.formatDate(this.dados.data_lancamento);
    },
    
    /*dateVencimentoFormatted() {
      return this.formatDate(this.lancamento.data_vencimento);
    },*/
  },
 

  watch: {
    v_autoselect(val) {
      //console.log("auto" + val);
    },
    "dados.data_inicial"(val) {
      this.dateInicialFormatted = this.formatDate(this.dados.data_inicial);
    },
    "dados.data_final"(val) {
      this.dateFinalFormatted = this.formatDate(this.dados.data_final);
    },
    
    dialog(val) {
      val || this.close();
    },
  
    dialogItens(val) {
      if (val == true) {
        this.buscaDadosItens();
      }
    },
  },
  methods: {
     buscaResultadoPdf() {
      this.loading = true;
      axios.post(
        `/fin/lancamento/resultado_operacional/pdf`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
            data_inicial: this.dados.data_inicial,
            data_final: this.dados.data_final,
            tipo_data:this.dados.tipo_data,
             compara:this.selmes
          }
       ).then(response => {
        if (response.data.error) {
          this.textSnack = response.data.error;
          this.snackbar = true;
        } else {
          document.getElementById("linkDownload").href = response.data.url;
          document.getElementById("linkDownload").click();
        }
        this.loading = false;
      });
    },
     buscaResultadoExcel() {
      this.loading = true;
      axios.post(
        `/fin/lancamento/resultado_operacional/excel`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
            data_inicial: this.dados.data_inicial,
            data_final: this.dados.data_final,
            tipo_data:this.dados.tipo_data,
             compara:this.selmes
          }
       ).then(response => {
        if (response.data.error) {
          this.textSnack = response.data.error;
          this.snackbar = true;
        } else {
          document.getElementById("linkDownload").href = response.data.url;
          document.getElementById("linkDownload").click();
        }
        this.loading = false;
      });
    },
    sumField(key, lista) {
        
        // sum data in give key (property)
        //console.log(lista)
        if(lista.length > 0){
          return  lista.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0)  
        }
        return 0;
       
    },
    listaMes(){
       // console.log('busca meses')
       
        
        this.buscaMeses()
       
        //this.listaMeses =[{'mes':1,'mesAno':'jan/21'},{'mes':2,'mesAno':'fev/21'},{'mes':3,'mesAno':'Mar/21'}]
        console.log(this.listaMeses);
    },
    gerarExcel(){
      alert('gerar Excel')
    },
    gerarPdf(){
      //alert('gerar Pdf')
      this.buscaExtratoPdf();
    },
    buscaMesesAno(){
        let now = new Date();
        let mes = now.getMonth()+1;
        let ano =  now.getFullYear();
       // let mesT;
        //var mesOfYear = [];
        let meses = now.getMonth()+1;
        console.log(meses)
        for (let  mesi = 0 ; mesi < meses ; mesi++) {                  
            //let mest = mesi+1
            this.listaMesesAno.push({mes:mesi+'-'+ano,mesAno:this.mesTranslateLong(mesi)});

        }

    },
    
    buscaMeses(){
        let now = new Date();
        let mes = now.getMonth()+1;
        let ano =  now.getFullYear();
        let mesT;
        //var mesOfYear = [];
        let meses = 12
        for (let  mesi = 0 ; mesi < meses ; mesi++) {          
          
            if(mes==1){
              mes = 12
              mesT = 11
              ano = ano-1;
            }else{
              mes--;
              mesT=mes -1;
            }
            this.listaMeses.push({mes:mes+'-'+ano,mesAno:this.mesTranslate(mesT)+'/'+ano});

        }

    },
    mesTranslate(mes){
      var meses = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez"
      ]; 
      return meses[mes];
    },
     mesTranslateLong(mes){
      var meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setetembro",
        "Outubro",
        "Novembro",
        "Dezembro"
      ]; 
      return meses[mes];
    },
    buscaContas(val) {
      this.listaContas = [];
      axios
        .post(
          `/fin/conta/lista`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
          },
          auth
        )
        .then((response) => {
          this.listaContas = response.data;
        });
    },
    zeraCampos() {
      //   alert("campos");
      this.dados.id_conta = "";
      this.valorSel = "";
    },
    mudaTab(tab) {
      //alert("conta" + conta);
      //this.conta;
      //this.lancamento.id_conta = e;
      //alert(e);
      //  this.$refs.lancamento.selConta(e);
      this.$emit("mudaTab", tab);
    },
    atrribuirConta(val) {
      this.valorSel = val;
      for (let [key, value] of Object.entries(this.listaContasPagar)) {
        //console.log(`${key}: ${value}`);
        this.listaContasPagar[key].id_conta = val;
        //this.listaContasPagar[key].data_pagamento = val;
      }
    },
    addContaItem(id_lancamento, index, e) {
      // this.lancSelPagar.push({ id_lanc: id_lancamento, conta: e });
      //alert(id_lancamento);
      //  alert(e.target.value);
      // console.log("indkkk" + index);
      // console.log(this.lancSelPagar);
      this.listaContasPagar[index].id_conta = e;
    },
    buscaDadosItens() {
      setTimeout(
        () => this.$refs.dialogIt.buscaLancamentosFilhos(this.id_pai),
        300
      );
    },
    openFilhos(id_pai) {
      this.id_pai = id_pai;
      setTimeout(() => (this.dialogItens = true), 200);
    },
    
    formata_moeda(val) {
      let valor = this.formatar_moeda(val);
      return valor;
    },
    addDescricao(val) {
      this.dados.descricao = val;
    },
    selecionarConta(id) {
      this.dados.id_conta = id;
      //  sessionStorage.setItem("selconta", id);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getStatus(status) {
      if (status === "Cancelada") {
        return "cancelada";
      }
    },

    selConta(e) {
      //console.log(e);
      //this.lancamento.id_conta = e;
      // alert(e);
      //this.$refs.lancamento.selConta(e);
    },
    buscaSaldos() {
      this.$refs.saldos.buscaSaldos();
    },
    filtroBuscar() {
      setTimeout(() => this.buscaContasPagar(), 500);
    },
    
    buscaResultado() {
      this.listaMes()
      this.loading_table = true; 
      this.listaVendas   = []
      this.listaCompras  = []
      this.listaDespesas = []
      this.listaInvestimentos = []
      this.listaOutrosCreditos = []
      this.listaOutrosDebitos =[]
     
      axios
        .post(
          `/fin/lancamento/resultado_operacional`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
            data_inicial: this.dados.data_inicial,
            data_final: this.dados.data_final,
            tipo_data:this.dados.tipo_data,
            compara:this.selmes
           
          }
        )
        .then((response) => {
          this.listaVendas = response.data.dados.vendas;
          this.listaCompras = response.data.dados.compras;
          this.listaDespesas = response.data.dados.despesas;
          this.listaInvestimentos = response.data.dados.investimentos;
          this.listaOutrosCreditos = response.data.dados.creditos;
          this.listaOutrosDebitos = response.data.dados.debitos;

          this.totalVendas = response.data.totalVendas
          this.loading_table = false;
          this.tabela = response.data.dados;
        }).catch((e)=>{
          this.loading_table = false;
        });
    },
   
    filtrarData() {
      this.filtroBuscar();
    },
    buscaContas(val) {
      this.listaContas = [];
      axios
        .post(
          `/fin/conta/lista`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
          },
          auth
        )
        .then((response) => {
          this.listaContas = response.data;
        });
    },
  },
  /*removeConta() {
    alert("remove");
  },*/
};
</script>
<style scoped>
.ativa {
  border: 2px solid #000 !important;
}
</style>
